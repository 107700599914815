import React from "react";

import MainTitle from "components/MainTitle";
import ListModels from "./components/ListModels";
import { Section, Container } from "components/Layout/styles";

function SectionModels() {
  return (
    <Section color="#F4F8FF">
      <Container>
        <MainTitle
          titleTag="h2"
          textTag="h3"
          title="Hotsites dedicados com alto poder de conversão"
          text="Crie sites dedicado para imóveis ou empreendimentos"
        />
        <ListModels
          data={[
            {
              name: "AZ10",
              site_url:
                "az10.com.br/hotsite/0cdaab24-80d3-4ad4-9e08-05859148da2e",
              imageUrl: "/hotsites/hotsite-AZ10.png",
            },
            {
              name: "Álvaro Maia Imóveis",
              site_url:
                "alvaromaia.com.br/hotsite/9e5814d4-bc25-46c1-aa66-87eb9956b256",
              imageUrl: "/hotsites/hotsite-alvaromaia.png",
            },
            {
              name: "Colibris Imóveis",
              site_url:
                "colibrisimoveis.com.br/hotsite/394a1990-62b2-4af8-b8da-85cab5a24488",
              imageUrl: "/hotsites/hotsite-colibrisimoveis.png",
            },
            {
              name: "Casa IAKO",
              site_url:
                "casaiako.com/bosc",
              imageUrl: "/hotsites/hotsite-casa-iako.png",
            },
            {
              name: "Lideral Imóveis",
              site_url:
                "lideralimoveis.com.br/hotsite/d039c15d-22f9-4180-ae04-93c474160beb",
              imageUrl: "/hotsites/hotsite-lideralimoveis.png",
            },
            {
              name: "Una Corretores Associados",
              site_url:
                "unacorretoresassociados.com.br/hotsite/8fff2ef0-8bdd-496c-9cce-7baf8752c5fd",
              imageUrl: "/hotsites/hotsite-uno.png",
            },
            {
              name: "Imobiliária AKATSUKA",
              site_url:
                "imobiliariaakatsuka.com.br/hotsite/9b75b1db-61c1-422c-be05-823485d57650",
              imageUrl: "/hotsites/hotsite-akatsuka.png",
            },
            {
              name: "Feitosa Imóveis",
              site_url:
                "imoveisfeitosa.com/hotsite/5e571001-4fb0-48b2-882d-0700007832a2",
              imageUrl: "/hotsites/hotsite-feitosa.png",
            },
          ]}
        />
      </Container>
    </Section>
  );
}

SectionModels.propTypes = {};
SectionModels.defaultProps = {};

export default SectionModels;
