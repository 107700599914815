import React from "react";
// Components
import MainSection from "components/MainSection";
import Image from "sections/modelos/LayoutOptions/Image";

export default function LayoutOptions() {
  return (
    <MainSection
      reversed
      title="Milhares de opções"
      text="Com uma grande quantidade de componentes, você pode combinar topos, rodapés, slides, e assim ter um site imobiliário exclusivo."
      image={<Image />}
      items={[
        "Infinitas possibilidades.",
        "Altere sempre que achar necessário."
      ]}
    />
  );
}
