import React from "react";
import PropTypes from "prop-types";
// Components
import ListCheck from "components/ListCheck";
// Styles
import { Wrapper, Title, Text } from "./styles";

function ContentSection({ title, text, items, children, ...rest }) {
  return (
    <Wrapper {...rest}>
      <Title>{title}</Title>
      <Text>{text}</Text>
      <ListCheck items={items} />
      {children}
    </Wrapper>
  );
}

ContentSection.defaultProps = {
  title: null,
  text: null,
  items: null
};

ContentSection.propTypes = {
  title: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  items: PropTypes.arrayOf(PropTypes.string)
};

export default ContentSection;
