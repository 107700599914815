import React from "react";
import PropTypes from "prop-types";
// Components
import Button from "components/Button";
// Styles
import {
  List,
  Image,
  ListItem,
  Overlay,
  WrapText,
  Title,
  Link
} from "./styles";

function ListModels({ data }) {
  return (
    <List>
      {data &&
        data.map((model, i) => {
          const link = `https://${model.site_url}`;
          return (
            <ListItem key={`LIST-${i}-${model.site_url}`}>
              <Image>
                <img src={model.imageUrl} alt={model.name} />
                <Overlay>
                  <Button
                    target="_blank"
                    as="a"
                    href={link}
                    color="secondary"
                    kind="solid"
                    rel="noopener noreferrer"
                  >
                    Ver site
                  </Button>
                </Overlay>
              </Image>
              <WrapText>
                <Title>{model.name}</Title>
                <Link target="_blank" href={link} rel="noopener noreferrer">
                  {model.site_url}
                </Link>
              </WrapText>
            </ListItem>
          );
        })}
    </List>
  );
}

ListModels.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      imageUrl: PropTypes.string
    })
  )
};
ListModels.defaultProps = {};

export default ListModels;
