export const createObserver = ({ offset, onIntersecting }) =>
  new IntersectionObserver(
    (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (onIntersecting) onIntersecting();

          // Remove o observer do elemento
          observer.unobserve(entry.target);
        }
      });
    },
    { rootMargin: `0px 0px ${offset} 0px` }
  );
