import React from "react";
// Components
import MainSection from "components/MainSection";
import Image from "sections/modelos/GoogleMaps/Image";

export default function GoogleMaps() {
  return (
    <MainSection
      title="Integrado ao Google Maps"
      text="Com o Google Maps, seu cliente terá a melhor experiência possível na hora de procurar um imóvel."
      image={<Image />}
      items={[
        "O mapa que todo mundo já conhece e confia.",
        "Rápido, prático e sempre disponível."
      ]}
    />
  );
}
