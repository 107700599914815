import styled from "styled-components";
import { Breakpoints } from "config-styles";
import { Wrapper as Button } from "components/Button/styles";

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: -15px;
`;

export const ListItem = styled.li`
  position: relative;
  padding: 15px;

  ${Breakpoints.mobile} {
    width: 100%;
  }

  ${Breakpoints.tablet} {
    width: 50%;
  }

  ${Breakpoints.tabletMin} {
    width: calc(100% / 4);
  }
`;

export const Image = styled.div`
  position: relative;
  box-shadow: -4px 4px 10px #e8f1ff;
  margin-bottom: 20px;

  img {
    width: 100%;
    border-radius: 4px;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);

  opacity: 0;
  transition: opacity 0.2s;
  will-change: opacity;

  ${ListItem}:hover & {
    opacity: 1;
  }

  ${Button} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const WrapText = styled.div`
  text-align: center;
`;

export const Title = styled.strong`
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0.3em;
`;

export const Link = styled.a`
  color: #0063c0;
`;
