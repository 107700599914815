import React from "react";
import { string } from "prop-types";

function Sources({ alt, src, srcSet }) {
  // Define o array de elementos
  let elements = [];

  // Verifica se tem sources
  // se tiver adiciona nos elementos
  if (srcSet) {
    srcSet.forEach(({ srcSet, type }) => {
      elements = [
        ...elements,
        <source key={srcSet} srcSet={srcSet} type={type} />
      ];
    });
  }

  return [...elements, <img key={src} src={src} alt={alt} />];
}

Sources.defaultProps = {
  src: null,
  srcSet: null,
  alt: null
};

Sources.propTypes = {
  src: string.isRequired,
  srcSet: string,
  alt: string
};

export default Sources;
