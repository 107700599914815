import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Sources from "./Sources";
import { createObserver } from "./helpers";

function Image({ src, srcSet, offset, alt, className }) {
  const [srcState, setSrc] = useState(null);
  const [srcSetState, setSrcSet] = useState(null);
  const image = useRef();

  // Verifica se é uma imagem responsiva
  const isPicture = !!Array.isArray(srcSet);

  // Parametros da imagem
  let params = {};

  if (srcState) {
    params = { src: srcState };
  }

  useEffect(() => {
    const observer = createObserver({
      offset,
      onIntersecting: () => {
        setSrc(src);
        setSrcSet(src);
      }
    });

    // Observa a imagem atual
    observer.observe(image.current);
  }, [src, offset]);

  // Verifica se é uma picture
  if (isPicture) {
    return (
      <picture ref={image} className={className}>
        <Sources src={srcState} srcSet={srcSetState} alt={alt} />
      </picture>
    );
  }

  return <img ref={image} alt={alt} className={className} {...params} />;
}

Image.defaultProps = {
  src: null,
  alt: null,
  offset: "200px",
  srcSet: null
};

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  offset: PropTypes.string,
  srcSet: PropTypes.arrayOf(
    PropTypes.shape({
      srcSet: PropTypes.string.isObject,
      type: PropTypes.string
    })
  )
};

export default Image;
