import React from "react";
import PropTypes from "prop-types";
// Styles
import { Wrapper, Item } from "./styles";

function ListCheck({ items }) {
  if (!items || items.length <= 0) return null;

  return (
    <Wrapper>
      {items.map((item, index) => (
        <Item key={index}>{item}</Item>
      ))}
    </Wrapper>
  );
}

ListCheck.defaultProps = {
  items: []
};

ListCheck.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string)
};

export default ListCheck;
