import styled, { css } from "styled-components";
import { Breakpoints } from "config-styles";

export const Section = styled.section`
  position: relative;
  width: 100%;

  padding: ${(props) => {
    const [py, px] = props.spacing;
    return `${py}px ${px}px`;
  }};
  background-color: ${(props) => props.color};

  ${Breakpoints.tabletMax} {
    padding: 30px 20px;
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${Breakpoints.tabletMax} {
    width: 100% !important;
  }
`;

export const ColImage = styled(Col)`
  width: 60%;

  .gatsby-image-wrapper {
    width: 100%;
    min-width: 0;
    flex-shrink: 0;
  }
  
  img {
    max-width: 100%;
    
    ${({ reversed }) => {
      return reversed
        ? css`
            margin-right: auto;
          `
        : css`
            margin-left: auto;
          `;
    }}
`;

export const ColContent = styled(Col)`
  width: 40%;
`;

const positionImage = ({ reversed }) => {
  if (reversed) {
    return css`
      align-self: flex-start;
    `;
  }

  return css`
    align-self: flex-end;
  `;
};

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  max-width: ${(props) => props.theme.baseWidth};
  margin: 0 auto;

  @media all and (min-width: 40em) {
    flex-direction: ${(props) => (props.reversed ? "row-reverse" : "row")};

    .gatsby-image-wrapper {
      ${positionImage}
    }

    ${Col}:last-child {
      ${(props) =>
        props.reversed
          ? css`
              padding-right: 40px;
            `
          : css`
              padding-left: 40px;
            `}
    }
  }

  @media all and (max-width: 40em) {
    flex-direction: column;

    .gatsby-image-wrapper {
      margin-top: 25px;
    }
  }
`;

export const GroupSections = styled.div`
  ${Section}:first-child {
    padding-top: 0;
  }
`;
