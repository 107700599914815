import styled from "styled-components";
import icon from "images/icons/icon-check.svg";

export const Wrapper = styled.ul`
  list-style: none;
`;

export const Item = styled.li`
  position: relative;
  color: #1c0c1e;
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 10px 0;

  &::before {
    content: url(${icon});
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
`;
