import React from "react";
import PropTypes from "prop-types";
import { Section, Container, ColContent, ColImage } from "./styles";
// Components
import ContentSection from "components/ContentSection";

function MainSection({
  id,
  spacing,
  title,
  text,
  items,
  image,
  reversed,
  color,
  children,
  className,
}) {
  return (
    <Section className={className} id={id} color={color} spacing={spacing}>
      <Container reversed={reversed}>
        <ColContent>
          <ContentSection title={title} text={text} items={items}>
            {children}
          </ContentSection>
        </ColContent>
        <ColImage reversed={reversed}>{image}</ColImage>
      </Container>
    </Section>
  );
}

MainSection.defaultProps = {
  id: null,
  spacing: [60, 20],
  title: null,
  text: null,
  color: null,
  items: null,
};

MainSection.propTypes = {
  id: PropTypes.string,
  spacing: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  color: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
};

export default MainSection;
