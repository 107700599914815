import React from "react";
// Components
import MainSection from "components/MainSection";
import Image from "components/Image";

export default function ConnectedWhatsapp() {
  return (
    <MainSection
      title="Conectado ao WhatsApp"
      text="Facilitamos a conexão entre você e seu cliente, ligando ele diretamente ao seu WhatsApp em pontos estratégicos do seu site imobiliário."
      image={<Image src="/modelos/chat.svg" alt="Conectado ao WhatsApp" />}
      items={[
        "Tenha o histórico de tudo o que conversaram.",
        "Compartilhe seus imóveis diretamente do seu sistema e aplicativo."
      ]}
    />
  );
}
