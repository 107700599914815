import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

export default function Image() {
  const data = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "sections/modelos/modelos.png" }) {
        ...mobileImage
      }
      desktopImage: file(relativePath: { eq: "sections/modelos/modelos.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
    }
  `);

  // Set up the array of image data and `media` keys.
  // You can have as many entries as you'd like.
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`
    }
  ];

  return <Img fluid={sources} alt="Milhares de opções" />;
}
