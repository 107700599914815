import React from "react";
// Components
import Seo from "components/Seo";
import Layout from "components/Layout";
// Sections - Global
import Cta from "sections/global/Cta";
import Testimonials from "sections/global/Testimonials";
// Sections - Modelos
//import MainSectionModels from "sections/modelos/MainSectionModels";
import LayoutOptions from "sections/modelos/LayoutOptions";
import ConnectedWhatsapp from "sections/modelos/ConnectedWhatsapp";
import GoogleMaps from "sections/modelos/GoogleMaps";
import SectionModels from "sections/modelos/SectionModels";
import SectionHotsites from "sections/modelos/SectionHotsites";

export default function Modelos(props) {
  return (
    <Layout {...props} headerColor="#F4F8FF">
      <Seo
        title="Modelos de site para imobiliária e corretor de imóveis"
        description={`Combine entre topos, rodapés e slides. Crie um site 
        imobiliário exclusivo.`}
      />
      {/*<ExamplesModels />*/}
      <SectionModels />
      <SectionHotsites />
      {/*<MainSectionModels />*/}
      <LayoutOptions />
      <ConnectedWhatsapp />
      {/*<GooglePerformance />*/}
      <GoogleMaps />
      <Testimonials />
      <Cta />
    </Layout>
  );
}
