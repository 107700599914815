import styled from "styled-components";
import { H3, P } from "components/Typograph";
import { Wrapper as WrapperListCheck } from "components/ListCheck/styles";

export const Wrapper = styled.div`
  width: 100%;

  ${WrapperListCheck} {
    margin-bottom: 15px;
  }
`;

export const Title = styled(H3).attrs(() => ({
  as: "h3"
}))`
  margin-bottom: 15px;

  @media all and (max-width: 40em) {
    font-size: 20px;
  }
`;

export const Text = styled(P)`
  margin-bottom: 15px;
  line-height: 32px;

  @media all and (max-width: 40em) {
    font-size: 14px;
    line-height: 1.8em;
  }
`;
